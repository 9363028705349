import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "tipContent"
};
const _hoisted_2 = {
  class: "head"
};
const _hoisted_3 = {
  class: "content"
};
import listV1Vue from "./list-v1.vue";
import tipHeader from "./tip-header.vue";
export default {
  __name: 'tip-content',
  props: {
    dataList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    leftIcon: {
      default: ""
    },
    rightIcon: {
      default: ""
    },
    leftWord: {
      default: ""
    },
    rightWord: {
      default: ""
    },
    addStyle: {}
  },
  emits: ['handleCancel'],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const dealClick = () => {
      emit('handleCancel');
    };
    return (_ctx, _cache) => {
      const _component_el_image = _resolveComponent("el-image");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(tipHeader, {
        rightWord: __props.rightWord,
        leftWord: __props.leftWord,
        onDealClick: dealClick
      }, _createSlots({
        _: 2
      }, [__props.leftIcon ? {
        name: "leftIcon",
        fn: _withCtx(() => [_createVNode(_component_el_image, {
          src: __props.leftIcon
        }, null, 8, ["src"])]),
        key: "0"
      } : undefined, __props.rightIcon ? {
        name: "rightIcon",
        fn: _withCtx(() => [_createVNode(_component_el_image, {
          src: __props.rightIcon
        }, null, 8, ["src"])]),
        key: "1"
      } : undefined]), 1032, ["rightWord", "leftWord"])]), _createElementVNode("div", _hoisted_3, [_createVNode(listV1Vue, {
        listData: __props.dataList,
        addStyle: __props.addStyle
      }, null, 8, ["listData", "addStyle"])])]);
    };
  }
};