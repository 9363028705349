import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-51f5c30c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "listV1"
};
const _hoisted_2 = ["onClick"];
import useSearchStore from '@/store/searchStore';
export default {
  __name: 'list-v1',
  props: {
    listData: {
      type: Array,
      default() {
        return [];
      }
    },
    addStyle: {}
  },
  setup(__props) {
    const store = useSearchStore();
    const handleClick = item => {
      store.changeSearchWord(item.title);
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.listData, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass(["item pointerCursor", __props.addStyle === 'V1' ? 'addStyleV1' : '']),
          onClick: _withModifiers($event => handleClick(item), ["stop"])
        }, _toDisplayString(item?.title), 11, _hoisted_2);
      }), 128))]);
    };
  }
};