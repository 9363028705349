import { defineStore } from "pinia";

const useSearchStore = defineStore('search',{
    state:()=>({searchWord:''}),
    actions:{
        changeSearchWord(val){
           this.searchWord = val
        }
    }
})

export default useSearchStore