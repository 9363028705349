import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0ef4284e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tipHeader"
};
const _hoisted_2 = {
  class: "left"
};
const _hoisted_3 = {
  class: "posLeft"
};
const _hoisted_4 = {
  class: "right"
};
const _hoisted_5 = {
  class: "posRight"
};
export default {
  __name: 'tip-header',
  props: {
    leftWord: {
      default: ''
    },
    rightWord: {
      default: ''
    }
  },
  emits: ['dealClick'],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const handleClick = () => {
      // alert(12312)
      emit('dealClick');
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "leftIcon")]), _createElementVNode("div", {
        class: "leftWord textCursor",
        onMousedown: handleClick
      }, _toDisplayString(__props.leftWord), 33)]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_renderSlot(_ctx.$slots, "rightIcon")]), _createElementVNode("div", {
        class: "rightWord pointerCursor",
        onClick: handleClick
      }, _toDisplayString(__props.rightWord), 1)])]);
    };
  }
};