import { KeepAlive } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import { getUserInfo } from '@/service'
import useUserInfoStore from '@/store/userInfoStore'

const routes = [
  {
    path: '/',
    redirect: "/product",
  },{
    path: "/product",
    name:'product',
    meta:{keepAlive:true},
    component: () => import("@/views/product-page.vue"),
  },{
    path: "/scene",
    name:'scene',
    meta:{keepAlive:true},
    component: () => import("@/views/scene-page.vue"),
  },{
    path: "/support",
    name:'support',
    meta:{keepAlive:false},
    component: () => import("@/views/support-page.vue"),
  },{
    path: "/search",
    name:'search',
    meta:{keepAlive:false},
    component: () => import("@/views/search-page.vue"),
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to,from)=>{
  if(document.getElementsByClassName('leader-line')){
    for(let i=0;i<document.getElementsByClassName('leader-line').length;i++){
      document.getElementsByClassName('leader-line')[i].style.display = 'none'
    }
  }
  const userInfoStore = useUserInfoStore()
  console.log('first:'+userInfoStore.first)
  if(userInfoStore.first){
    userInfoStore.changeFirstValue(false)
    const params = {action:"get_userinfo"}
    const res = await getUserInfo(params)
    // console.log('res:',res)
    // userInfoStore.changeUserInfo({username:'xx'})
    if(res?.reply === 'ok'){
      userInfoStore.changeUserInfo(res?.result||{})
      // console.log(userInfoStore.userInfo)
    }
  }
  return true
})

export default router
