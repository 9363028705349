import router from "@/router"  //userRouter只能在setup函数中使用
import showAlert from "./showAlert"
import useUserInfoStore from "@/store/userInfoStore"
import { getUserInfo } from "@/service"
export const toPage = async (item) =>{
    //不需要我进行验证了
    // const userInfoStore = useUserInfoStore()
    // if(item.isCheck){
    //     const params = {action:"get_userinfo"}
    //     const res = await getUserInfo(params)
    //     if(res?.reply === 'ok'){
    
    //     }else{
    //       if(userInfoStore.userInfo.username){
    //         showAlert('登录已失效,请重新登录!')
    //         userInfoStore.changeUserInfo({})
    //       }else{
    //         showAlert('请先进行登录!')
    //       }
    //       return
    //     }
    //   }
    if(item.url){
        if(item.url.indexOf('http')>-1){
            if(item.target==='blank'){
               window.open(item.url,'_blank')
            }else{
                window.location.href = item.url
            }
        }
    }else {
        showAlert('正在建设中...')
    }
}