import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-9a8f6298"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "myAlert"
};
const _hoisted_2 = {
  class: "alert"
};
import { ref } from 'vue';
export default {
  __name: 'myAlert',
  props: {
    title: {}
  },
  setup(__props) {
    const props = __props;
    const title_ = ref(props.title);
    const handleAlertClose = () => {
      title_.value = '';
    };
    return (_ctx, _cache) => {
      const _component_el_alert = _resolveComponent("el-alert");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_alert, {
        title: title_.value,
        type: "success",
        onClose: handleAlertClose
      }, null, 8, ["title"])])]);
    };
  }
};