import { createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-c71e9ac0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  id: "content"
};
const _hoisted_2 = {
  class: "content_"
};
import { emitter } from './utils/events';
import copyright from './components/copyright.vue';
import pageHeader from './components/page-header.vue';
import { useRoute } from 'vue-router';
import { computed, ref, onMounted } from 'vue';
import useHeaderStore from './store/headerStore';
export default {
  __name: 'App',
  setup(__props) {
    const store = useHeaderStore();
    const route = useRoute();
    const backgroundColor = ref('rgba(255,255,255,0)');
    const trans = computed(() => {
      return store.hasTransition ? 'trans' : '';
    });
    const scrollHandle = e => {
      let top = e.srcElement.scrollingElement.scrollTop; // 获取页面滚动高度
      if (top > 62) {
        backgroundColor.value = `rgba(255,255,255,1)`;
      } else if (top <= 62) {
        backgroundColor.value = `rgba(255,255,255,${(62 - (62 - top)) / 62})`;
      }
      // if(top === 0){
      //   store.changeHasTransition(false)
      // }else{
      //   store.changeHasTransition(true)
      // }
    };
    window.addEventListener('scroll', scrollHandle); //绑定页面滚动事件
    onMounted(() => {
      document.getElementById('content').addEventListener('click', () => {
        emitter.emit('hiddenHandle');
      });
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", {
        class: _normalizeClass(["header", trans.value]),
        style: _normalizeStyle({
          backgroundColor: backgroundColor.value
        })
      }, [_createVNode(pageHeader)], 6), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_router_view, null, {
        default: _withCtx(({
          Component
        }) => [(_openBlock(), _createBlock(_KeepAlive, null, [_unref(route).meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
          key: _unref(route).name
        })) : _createCommentVNode("", true)], 1024)), !_unref(route).meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
          key: _unref(route).name
        })) : _createCommentVNode("", true)]),
        _: 1
      })]), _createVNode(copyright)])], 64);
    };
  }
};