import { defineStore } from "pinia";

const useHeaderStore = defineStore("header",{
    state(){
        return {hasTransition:true}
    },
    actions:{
       changeHasTransition(val){
        this.hasTransition = val
       }
    }
})

export default useHeaderStore