import axios from "axios";
import { BASE_URL,TIME_OUT } from "./config";

class DevplatRequest {
  constructor(baseURL, timeout) {
    this.instance = axios.create({
      baseURL: baseURL,
      timeout: timeout,
    });
    //请求拦截
    this.instance.interceptors.request.use((config)=>{
      // console.log(config)
      // if(config.method === 'post'){
      //   config.data = config.data || {};
      //   config.data = JSON.stringify(config.data);
      // }
      return config
    })
    //返回拦截
    this.instance.interceptors.response.use((res) => {
      // console.log(res)
      return res.data;
    });
  }
  requst(config){
    return new Promise((resolve,reject)=>{
        this.instance.request(config).then(res=>{
          // console.log(res)
            resolve(res)
        }).catch(err=>{
            reject(err)
        })
    }).catch(err=>{
        return {}   
    })
  }
  get(config){
    return this.requst({...config,method:'get'})
  }
  post(config){
    return this.requst({...config,method:'post'})
  }
}

const dplatRequest = new DevplatRequest(BASE_URL,TIME_OUT)

export default dplatRequest
