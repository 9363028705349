import dplatRequest from "../request";

//获取用户信息
export const getUserInfo = (params) => {
    return dplatRequest.post({data:params})
}

//退出登录
export const userLogout = params => {
    return dplatRequest.post({data:params}) 
}