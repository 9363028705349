import { createApp } from 'vue'
import myAlert from '@/components/myAlert.vue'

const showAlert = (title='', options = { duration: 1000 }) => {
  const div = document.createElement('div')
  const componentInstance = createApp(myAlert, {
    title
  })

  componentInstance.mount(div)
  document.body.appendChild(div)

  let timer = null
  clearTimeout(timer)
  timer = setTimeout(() => {
    componentInstance.unmount(div); 
    document.body.removeChild(div);
  }, options.duration)
}

export default showAlert