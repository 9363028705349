import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import pinia from './store'
import 'amfe-flexible'
import '@/assets/css/index.less'
import 'normalize.css'

function px2rem(px){
    if(/%/ig.test(px)){ // 有百分号%，特殊处理，表述pc是一个有百分号的数，比如：90%
      return px
    }else{
      return (parseFloat(px) / 192) + 'rem'
    }
}
window.addEventListener('error', (error) => {
  console.log('捕获到异常：', error);
}, true)

const app = createApp(App)
app.provide('$px2rem', px2rem) // 将getAction方法挂载到全局

app.use(pinia).use(router).mount('#app')
