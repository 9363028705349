import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-16e30d8d"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "copyright"
};
const _hoisted_2 = {
  class: "word textCursor"
};
const _hoisted_3 = {
  class: "word textCursor"
};
export function render(_ctx, _cache) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, "版权所有© 1999-" + _toDisplayString(new Date().getFullYear()) + " 深圳市茁壮网络股份有限公司 粤ICP备12069260", 1), _createElementVNode("div", _hoisted_3, "Copyright @ 1999-" + _toDisplayString(new Date().getFullYear()) + " iPanel.TV Inc.,All Rights Reserved", 1)]);
}