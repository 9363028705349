import { defineStore } from "pinia";

const useUserInfoStore = defineStore('userInfo',{
    state(){
        return {
             userInfo:{},
             first:true
        }
    },
    actions:{
        changeUserInfo(val){
            this.userInfo = val
        },
        changeFirstValue(val){
            this.first = false
        }
    }
})

export default useUserInfoStore